<template>
    <div class="hot-line-card">
        <WarningIcon class="icon"/>
        <a href="tel:1900 1910">
          <span>HOTLINE: 1900 1910</span>
        </a>
    </div>
</template>
<script>

import WarningIcon from '@/assets/warning-icon.svg'
export default {
  components: {
    WarningIcon
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
    @import "@/scss/respoinsive";
    .hot-line-card {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        background-color: #EFF9F8;
        padding: 12px 16px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        .icon {
            margin-right: 8px;
        }
        @include tablet() {
            display: none;
        }
    }
</style>
