<template>
    <button
        class="hd-button"
        :type="nativeType"
        @click="handleClick"
        :disabled="disabled"
        :class="[
            type ? 'hd-button--' + type : '',
            size ? 'hd-button--' + size : '',
            fullWidth ? 'hd-button--full-width' : ''
        ]"
    >
        <i class="hd-button__icon" v-if="$slots.icon && iconPosition==='left'">
            <slot name="icon"></slot>
        </i>
        <span v-if="$slots.default"><slot></slot></span>
        <i class="hd-button__icon" v-if="$slots.icon && iconPosition==='right'">
            <slot name="icon"></slot>
        </i>
    </button>
</template>

<script>

export default {
  name: 'HdButton',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    fullWidth: Boolean,
    iconPosition: {
      type: String,
      default: 'right'
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    size: String,
    disabled: Boolean
  },
  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "@/scss/respoinsive";
    .hd-button {
        font-family: "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 38px;
        height: 40px;
        border: 1px solid #f36f20;
        border-radius: 15px;
        color: #000000;
        background-color: #f36f20;
        text-align: center;
        padding: 0 8px;
        text-decoration: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        @include tablet() {
            padding: 0 24px;
        }
        &:hover {
            opacity: 0.75;
        }
        &:active,
        &:disabled {
            opacity: 0.5;
            background-color: #cccccc;
            color: #666666;
            border: 1px solid #999999;
        }
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &--selected-box {
          background-color: #FFCFA7;
          text-align: left;
          span {
            flex: 1;
          }
        }
        &--header-search-box {
          background-color: #EBF6F5;
          border-color: #ffff;
        }
        &--promotion-card {
          @include tabletDown () {
            font-size: 12px;
            height: 30px;
            line-height: 28px;
          }
        }
    }
    .hd-button__icon + span, span + .hd-button__icon {
        margin-left: 8px;
    }
    .hd-button--full-width {
        width: 100%;
    }
</style>
