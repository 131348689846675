<template>
  <div class="page product-page">
    <Header />
    <HotlineMobileCard/>
    <div class="container" style="margin-top: 0; margin-bottom: auto;">
        <div class="h5 font-weight--sm--400 text--black text--sm--center mb--sm--20 mt--sm--15 forTabletUp">Danh sách sản phẩm</div>
        <ProductFilterBar/>
        <div class="row">
          <div class="col col--sm--12 col--md--6 mb--sm--3 mb--md--6 flagSearch" v-for="product in products" :key="product.productCode">
            <ProductCard
              :product="product"
            />
          </div>
          <div class="col col--sm--12 d--sm--flex justify-content--sm--center align-items--sm--center flex-direction--sm--column mb--sm--9 mb--md--20 mt--sm--9 mt--md--20" v-if="isEmpty">
            <div class="d--sm--flex justify-content--sm--center mb--sm--12 mb--md--20">
              <NoMessageIcon/>
            </div>
            <div class="text--sm--center mb--sm--12 mb--md--20">Không có sản phẩm nào để hiển thị!</div>
            <div class="text--sm--center">
              <HdButton v-on:click="$router.push('/').catch(()=>{})">
                  Quay về trang chủ
                  <template v-slot:icon><ArrowRightIcon/></template>
              </HdButton>
              </div>
          </div>
        </div>
        <div class="d--sm--flex justify-content--sm--center mt--sm--10 mb--sm--10" v-if="(pagination.total / pagination.pageSize) > 1">
          <el-pagination
            @current-change="handlePaginationCurrentChange"
            :current-page="pagination.currentPage"
            :page-size="pagination.pageSize"
            layout="prev, pager, next"
            :total="pagination.total">
          </el-pagination>
        </div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
// Import Components
import HdButton from '@/components/HdButton'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import ProductCard from '@/components/ProductCard.vue'
import ProductFilterBar from '@/components/ProductFilterBar.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'
import ArrowRightIcon from '@/assets/arrow-right.svg'
import NoMessageIcon from '@/assets/no-message.svg'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    ProductCard,
    ProductFilterBar,
    HotlineMobileCard,
    ArrowRightIcon,
    HdButton,
    NoMessageIcon
  },
  methods: {
    handlePaginationCurrentChange (currentPage) {
      this.$router.replace({ query: parseInt(currentPage) === 1 ? {} : { page: currentPage.toString() } })
      this.$store.commit('product/setPaginationCurrentPage', currentPage)
      this.$store.dispatch('product/getProducts')
    }
  },
  computed: {
    pagination: {
      get () {
        return this.$store.state.product.pagination
      },
      set (pagination) {
        this.$store.commit('product/setPagination', pagination)
      }
    },
    isEmpty () {
      return this.$_.isEmpty(this.$store.state.product.products)
    },
    products () {
      return this.$store.state.product.products
    }
  },
  data () {
    return {}
  },
  mounted () {
    // Config layout on mobile
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)

    // Get Data
    // Set Current Page Before get products (Current Page Default: 1)
    this.$store.commit('product/setPaginationCurrentPage', parseInt(this.$route.query.page) || 1)
    // Set groupCode by groupCode get from router params.
    this.$store.commit('product/setGroupCode', this.$route.params.groupCode)
    // After get groupCode dispatch get products by groupCode
    // if groupCode is empty not filter by groupCode.
    this.$store.dispatch('product/getProducts')
  }
}
</script>

<style lang="scss">
  .flagSearch:nth-child(2n+2) {
    .product-card__info {
      background-color: #eff9f8;
    }
  }
</style>
