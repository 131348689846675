<template>
    <div class="product-filter-bar mb--sm--3 mb--md--6 mt--sm--3 mt--md--6">
        <div class="product-filter-bar__filter-type h5 d--sm--flex align-items--sm--center">
            <el-dropdown trigger="click" v-if="groupCurrentProduct">
                  <span class="el-dropdown-link" v-if="groupCurrentProduct.groupName">{{groupCurrentProduct.groupName}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="groupProductItem in groupProduct" :key="groupProductItem.groupCode"><div v-on:click="groupClickHandle(groupProductItem.groupCode)" v-if="groupProductItem.groupName">{{groupProductItem.groupName}}</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
        </div>
        <div class="product-filter-bar__sort-price h5 d--sm--flex align-items--sm--center">
            <span>Giá: </span>
            <el-select v-model="sortOptionSelected" placeholder="Chọn" v-on:change="sortPriceChangeHandle">
                <el-option
                  v-for="sortItem in sortOption"
                  :key="sortItem.value"
                  :label="sortItem.label"
                  :value="sortItem.value">
                </el-option>
            </el-select >
        </div>
    </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {
    groupClickHandle: function (groupCode) {
      // Set groupCode Router
      this.$router.push(`/category/${groupCode}`).catch(() => {})
      // Set groupCode State
      this.$store.commit('product/setGroupCode', groupCode)
      // Get products
      this.$store.dispatch('product/getProducts')
    },
    sortPriceChangeHandle: function () {
      this.$store.dispatch('product/getProducts')
    }
  },
  mounted () {
    this.$store.dispatch('product/getGrProductAll')
  },
  computed: {
    groupCurrentProduct () {
      return this.$store.state.product.groupProduct.find(group => group.groupCode === this.$route.params.groupCode)
    },
    groupProduct () {
      return this.$store.state.product.groupProduct.filter(group => group.groupCode !== this.$route.params.groupCode)
    },
    sortOption () {
      return this.$store.state.product.sortOption
    },
    sortOptionSelected: {
      get () {
        return this.$store.state.product.sortOptionSelected
      },
      set (payload) {
        this.$store.commit('product/setSortOptionSelected', payload)
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/scss/respoinsive";
.product-filter-bar__filter-type {
  .el-dropdown {
    color: #003B35;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    @include mobile() {
      font-size: 16px;
    }
  }
}
.product-filter-bar__sort-price {
  >span {
    color: #003B35;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    @include mobile() {
      font-size: 16px;
    }
  }
  .el-input__inner {
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #B93D00;
  }
  .el-select {
    max-width: 130px;
    width: 100%;
  }
  .el-select .el-input .el-select__caret {
    color: #B93D00;
  }
}

    .product-filter-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
</style>
